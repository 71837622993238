/* ! universal */

body {
    background-color: #282c34;
    color: white;
}

/* !company dropdown */
/* .company {
    padding: 20px;
    margin-top: 5px;
    border: 2px solid teal;
    border-radius: 5px;
    background-color: #404652;
} */
